<template>
  <div v-if="copy"
       class="py-16 text-white lg:py-24"
  >
    <Container>
      <h2 class="text-4xl lg:text-5xl tracking-[-1px] font-medium leading-[1.1]">
        {{ copy.title }}
      </h2>
    </Container>

    <Cards class="pt-12 lg:pt-12"
           :cards="copy.items.filter((item:any) => item.cta.href !== path)"
           :slider-desktop-breakpoint="3"
           :aspect-ratio="0.75"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAsyncData, queryContent } from '#imports';

// get current route
const { path } = useRoute();

// Fetch locations from globals
const { data } = await useAsyncData('locations', () => queryContent('globals', 'locations').findOne());

const copy = computed(() => {
  if (data && data.value) {
    return data.value;
  }

  return false;
});
</script>
